import React from 'react';

import Layout from '../components/layout';

import '../styles/digital-twins.css';
import { navigate } from 'gatsby';

const DigitalTwins = props => {
    return (
        <Layout location={props.location}>
            <div className='digital-twins-page'>
                <div className='digital-twins-page__top'>
                    <div className='digital-twins-page__top__left'>
                        <h1 className='digital-twins-page__top__left__title'>Digital Twins</h1>
                        <p className='digital-twins-page__top__left__detail'>Modeling and simulation (M&S) is increasingly used by both public and private organizations in areas such as decision support, scenario planning, training & preparedness. The notion of Digital Twin is an example of how modeling & simulation extends the usage of M&S tools and techniques into real-time real-life operations.<br />
                            <br />
                            Digital twin refers to a digital replica of potential and actual physical assets (physical twin), processes, people, places, systems and devices that can be used for various purposes such as risk assessment, contingency planning.<br />
                            <br />
                    Digital twins integrate internet of things, artificial intelligence, machine learning to create living digital simulation models that update and change as their physical counterparts change.<br />
                            <br />
                    A digital twin continuously learns and updates itself from multiple sources to represent its near real-time status, working condition or position.
                    Our Dataunitor <u onClick={() => navigate('/hymots')}>{'hymots'}</u><sup><small>®</small></sup> platform coupled with <u onClick={() => navigate('/nginr')}>{'nginr'}</u> <sup><small>®</small></sup> is a perfect match to build digital twin solutions.</p>
                    </div>
                    <img className='digital-twins-page__top__image' src='/images/Modeling__Simulation.png' alt='port security simulator' />
                </div>
            </div>
        </Layout>
    );
}

export default DigitalTwins;